import { File as FileModel } from '../../models/File'
import { useAppSelector } from '../../reducers/hooks'
import { Card, Space, Tooltip, Typography } from 'antd'
import { selectFileById } from '../../reducers/FileReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { File } from './File'
import IconForFileExtension from '@supplement-bacon/alela-uikit/dist/esm/components/utils/IconForFileExtension'

const { Text } = Typography

interface Props {
  id?: number
  model?: FileModel
  withDeleteButton?: boolean
}

export const FileCard = ({ id, model, withDeleteButton = true }: Props) => {
  const file = model ? model : useAppSelector(selectFileById(id ?? 0))

  return file ? (
    <Card
      className="text-center"
      extra={
        withDeleteButton && (
          <Actions
            actions={['update', 'delete']}
            entity={SBRMType.file}
            entityId={file.id}
            layout={'dropdown'}
          />
        )
      }
      styles={{
        body: { paddingTop: 0, cursor: 'pointer' },
        header: { padding: 5, minHeight: 'unset' },
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {/* The view button that open the file viewer */}
        <Actions
          actions={['view']}
          entity={SBRMType.file}
          entityId={file.id}
          trigger={
            <>
              <IconForFileExtension
                extension={file.extension}
                style={{ fontSize: 40 }}
              />
              <Space direction="vertical" size={0} style={{ width: '100%' }}>
                <Tooltip title={file.name}>
                  <Text ellipsis={true}>{file.name}</Text>
                </Tooltip>
                <Text type="secondary">{file.size}</Text>
              </Space>
            </>
          }
        />

        {/* A useful horizontal buttons collection */}
        <Space direction="horizontal">
          <File.DownloadButton id={file.id} />
          <File.Share id={file.id} />
        </Space>
      </Space>
    </Card>
  ) : (
    <></>
  )
}

export type FileCardType = { Card: typeof FileCard }
