import { FC } from 'react'
import { Share, ShareType } from './Share'
import { FileCard, FileCardType } from './Card'
import { FileUpload, FileUploadType } from './Upload'
import { FileCell, FileCellType } from './Cell'
import { FileGrid, FileGridType } from './Grid'
import { FileDropzone, FileDropzoneType } from '../custom-inputs/Dropzone'
import { DownloadButton, DownloadButtonType } from './DownloadButton'
import { FileForm, FileFormType } from './form/Form'

export const File: FC &
  ShareType &
  FileCardType &
  FileCellType &
  FileGridType &
  FileUploadType &
  DownloadButtonType &
  FileFormType &
  FileDropzoneType = (): JSX.Element => <></>

File.Share = Share
File.Card = FileCard
File.Cell = FileCell
File.Grid = FileGrid
File.Upload = FileUpload
File.Dropzone = FileDropzone
File.DownloadButton = DownloadButton
File.Form = FileForm
