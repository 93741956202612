import { FC } from 'react'
import { Table, TableType } from './Table'
import { ContactsTable, ContactsTableType } from './ContactsTable'
import BookingTypeTag, { BookingTypeTagType } from './TypeTag'
import { BookingBarChart, BookingBarChartType } from './BarChart'
import { BookingAvatar, BookingAvatarType } from './Avatar'
import {
  MissingContactsInPartyCrew,
  MissingContactsInPartyCrewType,
} from './MissingContactsInPartyCrew'
import { BookingForm, BookingFormType } from './form/Form'
import { BookingAmountsTag, BookingAmountsTagType } from './AmountsTag'
import { Export, ExportType } from './Export'

export const Booking: FC &
  TableType &
  ContactsTableType &
  BookingBarChartType &
  BookingAvatarType &
  BookingTypeTagType &
  BookingFormType &
  MissingContactsInPartyCrewType &
  BookingAmountsTagType &
  ExportType = (): JSX.Element => <></>

Booking.Table = Table
Booking.ContactsTable = ContactsTable
Booking.TypeTag = BookingTypeTag
Booking.Avatar = BookingAvatar
Booking.BarChart = BookingBarChart
Booking.MissingContactsInPartyCrew = MissingContactsInPartyCrew
Booking.AmountsTag = BookingAmountsTag
Booking.Export = Export
Booking.Form = BookingForm
