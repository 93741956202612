import { forwardRef, Ref, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { Alert, Form } from 'antd'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import { MetaDataKey } from '../../../models/MetaData'
import {
  createPerformance,
  deletePerformance,
  updatePerformance,
} from '../../../reducers/PerformanceReducer'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import {
  getMetadata,
  SBRMFormInterface,
  SBRMFormProps,
} from '../../../modules/sbrm/SBRMModel'

export const PerformanceForm = forwardRef(
  ({ isNested }: SBRMFormProps, ref: Ref<SBRMFormInterface>) => {
    useImperativeHandle(ref, () => ({
      handleCreate,
      handleUpdate,
      handleDelete,
      handleReset,
    }))

    const dispatch = useAppDispatch()
    const { action, metadata } = useSBRMData(isNested)
    const { error } = useAppSelector((state) => state.room)

    const [createForm] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [deleteForm] = Form.useForm()

    const handleCreate = async () =>
      await createForm.validateFields().then(async (result) =>
        dispatch(
          createPerformance({
            ...createForm.getFieldsValue(),
            start: (createForm.getFieldValue('dates') ?? [null])[0],
            end: (createForm.getFieldValue('dates') ?? [null, null])[1],
          })
        ).then(() => {
          createForm.resetFields()
          return true
        })
      )

    const handleUpdate = async () =>
      await updateForm.validateFields().then(async (result) =>
        dispatch(
          updatePerformance({
            ...updateForm.getFieldsValue(),
            start: updateForm.getFieldValue('dates')[0],
            end: updateForm.getFieldValue('dates')[1],
          })
        ).then(() => {
          updateForm.resetFields()
          return true
        })
      )

    const handleDelete = async () =>
      await deleteForm.validateFields().then(async (result) =>
        dispatch(deletePerformance(deleteForm.getFieldsValue())).then(() => {
          deleteForm.resetFields()
          return true
        })
      )

    const handleReset = () => {
      createForm.resetFields()
      updateForm.resetFields()
      deleteForm.resetFields()
    }

    return (
      <>
        {action === 'create' && (
          <Create
            form={createForm}
            venuesId={getMetadata(metadata, MetaDataKey.venuesId)}
            stageId={getMetadata(metadata, MetaDataKey.stageId)}
            eventId={getMetadata(metadata, MetaDataKey.eventId)}
            bookingId={getMetadata(metadata, MetaDataKey.bookingId)}
            selectedFromDateTime={getMetadata(
              metadata,
              MetaDataKey.selectedFromDateTime
            )}
            selectedToDateTime={getMetadata(
              metadata,
              MetaDataKey.selectedToDateTime
            )}
          />
        )}
        {action === 'update' && (
          <Update
            form={updateForm}
            venuesId={getMetadata(metadata, MetaDataKey.venuesId)}
          />
        )}
        {action === 'delete' && <Delete form={deleteForm} />}
        {error && <Alert message={String(error)} type="error" />}
      </>
    )
  }
)

PerformanceForm.displayName = 'PerformanceForm'

export type PerformanceFormType = { Form: typeof PerformanceForm }
