import { Empty, List, Tag } from 'antd'
import { Comment } from './Comment'
import { Comment as CommentModel, ExternalComment } from '../../models/Comment'
import { useAppSelector } from '../../reducers/hooks'
import { selectCommentsByIds } from '../../reducers/CommentReducer'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { selectExternalCommentsByIds } from '../../reducers/ExternalCommentReducer'

interface Props {
  entity: SBRMType
  ids?: number[]
  models?: (CommentModel | ExternalComment)[]
}

export const CommentList = ({ entity, ids, models }: Props) => {
  const intl = useIntl()

  const comments = models
    ? models
    : useAppSelector(
        entity === SBRMType.externalAdvancingLink
          ? selectExternalCommentsByIds(ids ?? [])
          : selectCommentsByIds(ids ?? [])
      )

  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    listRef.current && listRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [comments.length])

  if (comments.length === 0)
    return (
      <Empty
        style={{
          position: 'relative',
          marginTop: 'calc(50% - 60px)',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            {intl.formatMessage({
              id: LocalizationKeys.Components.Comment.List.SendFirstMessage,
            })}
            <br />
            {intl.formatMessage(
              {
                id: LocalizationKeys.Components.Comment.List.UseTag,
              },
              { tag: <Tag>@</Tag> }
            )}
          </span>
        }
      />
    )

  return (
    <>
      <List
        style={{ width: '100%' }}
        size="large"
        dataSource={comments}
        renderItem={(item) =>
          entity === SBRMType.externalAdvancingLink ? (
            <Comment.ExternalCell id={item.id} />
          ) : (
            <Comment.Cell id={item.id} />
          )
        }
      />
      <div ref={listRef}></div>
    </>
  )
}

export type CommentListType = { List: typeof CommentList }
