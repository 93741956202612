import {
  CalendarOutlined,
  UserOutlined,
  BarChartOutlined,
  TeamOutlined,
  NotificationOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Space } from 'antd'
import { Link } from 'react-router-dom'
import RightHeader from './RightHeader'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useHasAccess } from '../../components/HasAccess'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { AlelaPermission } from '../../utils/permissions'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { NewTag } from '../../pages/misc/ReleaseNotesPage'

const { Header } = Layout

interface Props {
  subItems?: ItemType<MenuItemType>[] | undefined
}

const MainHeader = ({ subItems }: Props) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { hasAccess } = useHasAccess()

  const [selectedKey, setSelectedKey] = useState<string>('events')
  const [selectedSubKey, setSelectedSubKey] = useState<string>('contacts')

  const headerStyle: React.CSSProperties = {
    backgroundColor: '#1E1E1E',
    justifyContent: 'space-between',
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
  }

  const subHeaderStyle: React.CSSProperties = {
    backgroundColor: '#2C2C2C',
    justifyContent: 'space-between',
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
    height: 50,
    lineHeight: '50px',
  }

  useEffect(() => {
    setSelectedKey(extractSectionFromPath(pathname, 1))
    setSelectedSubKey(extractSectionFromPath(pathname, 2))
  }, [pathname])

  return (
    <div className={'fixed z-20 w-full'}>
      <Header style={headerStyle}>
        <>
          <Link to="/">
            <div className="logo d-flex">
              <img
                src={'/assets/images/logocurve.png'}
                height={45}
                style={{ marginTop: 12 }}
              />
            </div>
          </Link>
          <Menu
            className="desktop-menu d-flex-lg d-none-md d-none-sm d-none-xs"
            mode="horizontal"
            selectedKeys={[selectedKey]}
            items={[
              ...(hasAccess([AlelaPermission.listEvent])
                ? [
                    {
                      key: 'events',
                      label: (
                        <Link to={'/events'}>
                          {intl.formatMessage({
                            id: LocalizationKeys.Layout.MainHeader.Events,
                          })}
                        </Link>
                      ),
                      icon: <CalendarOutlined />,
                    },
                  ]
                : []),
              ...(hasAccess([AlelaPermission.listArtist])
                ? [
                    {
                      key: 'artists',
                      label: (
                        <Link to={'/artists'}>
                          {intl.formatMessage({
                            id: LocalizationKeys.Layout.MainHeader.Artists,
                          })}
                        </Link>
                      ),
                      icon: <UserOutlined />,
                    },
                  ]
                : []),
              ...(hasAccess([AlelaPermission.listInvoice])
                ? [
                    {
                      key: 'financials',
                      label: (
                        <Link to={'/financials'}>
                          {intl.formatMessage({
                            id: LocalizationKeys.Layout.MainHeader.Financials,
                          })}
                        </Link>
                      ),
                      icon: <BarChartOutlined />,
                    },
                  ]
                : []),
              //   ...(hasAccess([AlelaPermission.listMarketingTask])
              //     ? [
              //         {
              //           key: 'marketing',
              //           label: (
              //             <Link to={'/marketing'}>
              //               <Space>
              //                 {intl.formatMessage({
              //                   id: LocalizationKeys.Layout.MainHeader.Marketing,
              //                 })}
              //                 <NewTag />
              //               </Space>
              //             </Link>
              //           ),
              //           icon: <NotificationOutlined />,
              //         },
              //       ]
              //     : []),
              ...(hasAccess([AlelaPermission.listContact])
                ? [
                    {
                      key: 'crm',
                      label: (
                        <Link to={'/crm'}>
                          {intl.formatMessage({
                            id: LocalizationKeys.Layout.MainHeader.CRM,
                          })}
                        </Link>
                      ),
                      icon: <TeamOutlined />,
                    },
                  ]
                : []),
            ]}
          />
        </>
        <div
          style={{
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <RightHeader />
        </div>
      </Header>{' '}
      {subItems && (
        <Header style={subHeaderStyle}>
          <div
            style={{
              maxWidth: 1200,
              width: '100%',
              margin: 'auto',
            }}
          >
            <Menu
              style={{ background: '#2C2C2C', border: 'none' }}
              className="d-flex-lg"
              mode="horizontal"
              selectedKeys={[selectedSubKey]}
              items={subItems}
            />
          </div>
        </Header>
      )}
    </div>
  )
}

export default MainHeader
