import { selectBookingById } from '../../reducers/BookingReducer'
import { useEffect, useState } from 'react'
import { Contact } from '../contact/Contact'
import { Badge, Col, Collapse, List, Row, Space, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { getMissingContactsInPartyCrew } from '../../reducers/ContactReducer'
import {
  Contact as ContactModel,
  ContactMissingInPartyCrew,
} from '../../models/Contact'
import { Actions } from '../../modules/sbrm/components/Actions'
import {
  SBRMContactAssociationType,
  SBRMType,
} from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const { Text } = Typography
const { Panel } = Collapse

interface Props {
  id: number
}

export const MissingContactsInPartyCrew = ({ id }: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [display, setDisplay] = useState<boolean>(false)
  const [contacts, setContacts] = useState<ContactModel[]>([])
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const booking = useAppSelector(selectBookingById(id))
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    setDisplay(false)

    getMissingContactsInPartyCrew(booking?.id ?? 0)
      .then((result: { data: ContactMissingInPartyCrew }) => {
        setContacts(result.data.contacts)

        if (result.data.contacts.length > 0) {
          setDisplay(true)
        }
      })
      .catch((e) => {
        setDisplay(false)
      })
  }, [dispatch, SBRMIsOpen])

  if (display === false) return <></>

  return (
    <Collapse
      expandIconPosition={'end'}
      items={[
        {
          key: '1',
          label: (
            <Space>
              <Text>
                {intl.formatMessage({
                  id: LocalizationKeys.Components.Booking
                    .MissingContactsInPartyCrew,
                })}
              </Text>
              <Badge count={contacts.length} />
            </Space>
          ),
          children: (
            <Row>
              <Col span={24}>
                <List
                  itemLayout="horizontal"
                  dataSource={contacts}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Actions
                          key={'create'}
                          actions={['create']}
                          entity={SBRMType.contactAssociation}
                          metadata={[
                            {
                              key: MetaDataKey.contactAssociationType,
                              value: SBRMContactAssociationType.booking,
                            },
                            {
                              key: MetaDataKey.bookingId,
                              value: id,
                            },
                            {
                              key: MetaDataKey.contactId,
                              value: item.id,
                            },
                          ]}
                        />,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Contact.Avatar model={item} displayCompany={false} />
                        }
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          ),
        },
      ]}
    />
  )
}

export type MissingContactsInPartyCrewType = {
  MissingContactsInPartyCrew: typeof MissingContactsInPartyCrew
}
