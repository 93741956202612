import { Navigate, Route, Routes } from 'react-router-dom'
import { LayoutEventDetail } from '../../../layouts/LayoutEventDetail'
import FilesPage from '../../../pages/event-detail/FilesPage'
import LineUpPage from '../../../pages/event-detail/LineUpPage'
import EventProductionRoutes from './EventProductionRoutes'
import SettingsPage from '../../../pages/event-detail/SettingsPage'
import EventAdministrativeRoutes from './EventAdministrativeRoutes'
import EventContactsPage from '../../../pages/event-detail/EventContactsPage'
import TimeTablePage from '../../../pages/event-detail/TimeTablePage'
import MarketingPage from '../../../pages/event-detail/MarketingPage'
import TaskPage from '../../../pages/event-detail/TaskPage'

const EventDetailsRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutEventDetail />}>
        <Route index path="line-up" element={<LineUpPage />} />
        <Route path="timetable" element={<TimeTablePage />} />
        <Route path="tasks" element={<TaskPage />} />
        <Route path="production/*" element={<EventProductionRoutes />} />
        <Route
          path="administrative/*"
          element={<EventAdministrativeRoutes />}
        />
        <Route path="marketing" element={<MarketingPage />} />
        <Route path="files" element={<FilesPage />} />
        <Route path="contacts" element={<EventContactsPage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="*" element={<Navigate to={'line-up'} />} />
      </Route>
    </Routes>
  )
}

export default EventDetailsRoutes
