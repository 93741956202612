import {
  getNotifications,
  getNotificationsCount,
  readNotificationWithId,
  setNotificationQuery,
} from '../../reducers/NotificationReducer'
import { App, Avatar } from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../reducers/hooks'
import { TableParams } from '../../models/TableParams'
import { onMessageListener } from '../../utils/firebase'
import { initialQuery } from '../../utils/helpers/crud/models'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { removeUrlParams } from '../../modules/sbrm/UrlHelper'

export const Listener = () => {
  const dispatch = useAppDispatch()
  const { notification } = App.useApp()
  const [searchParams, setSearchParams] = useSearchParams()

  onMessageListener()
    .then((payload: any) => {
      notification.info({
        message: payload.notification.title,
        description: payload.notification.body,
        className: 'alela-notification',
        placement: 'bottomRight',
        icon: (
          <>
            {payload.data.logo ? (
              <Avatar shape="square" size={48} src={payload.data.logo} />
            ) : (
              <div
                style={{
                  borderRadius: 8,
                  width: '48px',
                  height: '48px',
                  backgroundColor: '#252525',
                  alignItems: 'center',
                  alignContent: 'center',
                  display: 'flex',
                }}
              >
                <PictureOutlined
                  style={{
                    color: '#333333',
                    margin: 'auto',
                    fontSize: 25,
                  }}
                />
              </div>
            )}
          </>
        ),
        onClick: () => {
          if (payload.data && payload.data.link) {
            let targetUrl = payload.data.link

            if (payload.data.notificationId) {
              // When a notification ID is present we need to add it to the current URL
              // As there might already be a some search params, be careful to keep them
              const url = new URL(targetUrl)

              const search = url.searchParams
              search.set('notificationId', payload.data.notificationId)

              targetUrl = url.origin + url.pathname + '?' + search.toString()
            }

            window.location.href = targetUrl
          }
        },
      })
      // Fetch notifications
      const baseQuery: TableParams = {
        ...initialQuery,
        pagination: { current: 1, pageSize: 20 },
      }
      dispatch(setNotificationQuery(baseQuery))
      dispatch(getNotifications(baseQuery))

      // Fetch notifications counts
      dispatch(getNotificationsCount())
    })
    .catch((e) => console.error(e))

  useEffect(() => {
    /**
     * When an user click on a browser notification it reloads the page with the notification url
     * This means when clicking there is no way to mark the notification as read
     *
     * In order to mark the notification as read, the notification url has a notificationId search parameter
     * If this paramter is spotted we juste have to mark the notification as read here
     */
    const notificationId = searchParams.get('notificationId')
    if (notificationId !== null && Number.isInteger(Number(notificationId))) {
      dispatch(readNotificationWithId(Number(notificationId)))
    }
  }, [searchParams])

  return <></>
}

export type ListenerType = { Listener: typeof Listener }
