import {
  selectContacts,
  selectContactsByIds,
} from '../../reducers/ContactReducer'
import { Space } from 'antd'
import { Contact } from './Contact'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from '../../reducers/hooks'
import { Contact as ContactModel } from '../../models/Contact'
import SBTable from '../SBTable'
import { MetaData } from '../../models/MetaData'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  ids?: number[]
  emptyActionsMetadata?: MetaData[]
}

export const TableWithAssociation = ({ ids, emptyActionsMetadata }: Props) => {
  const intl = useIntl()
  const contacts = ids
    ? useAppSelector(selectContactsByIds(ids))
    : useAppSelector(selectContacts())

  const { isLoading } = useAppSelector((state) => state.contact)

  const columns: ColumnsType<ContactModel> = [
    {
      key: 'contact',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Contact.TableWithAssociation.Contact,
      }),
      width: 200,
      render: (record: ContactModel) => (
        <Contact.Avatar id={record.id} linkToDetails />
      ),
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Components.Contact.TableWithAssociation.Type,
      }),
      render: (record: ContactModel) => (
        <Space direction="horizontal" wrap size={2}>
          {(record.associations ?? []).map((association) => (
            <Contact.Association key={association} id={association} />
          ))}
        </Space>
      ),
    },
  ]

  return (
    <SBTable
      entity={SBRMType.contactAssociation}
      emptyActionsMetadata={emptyActionsMetadata}
      loading={isLoading && !contacts.length}
      scroll={{ x: 800 }}
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={contacts}
    />
  )
}

export type TableWithAssociationType = {
  TableWithAssociation: typeof TableWithAssociation
}
