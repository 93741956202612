import { Avatar, Space, Tag, Tooltip, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { AvatarProps } from 'antd/es/avatar'
import { User as UserModel } from '../../models/User'
import { selectUserById } from '../../reducers/UserReducer'
import { UserOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useAuth } from '../../utils/auth/Auth'

const { Text } = Typography

interface Props {
  id?: number
  model?: UserModel
  size?: AvatarProps['size']
  type?: 'full' | 'condensed'
  tag?: string
  tagIcon?: JSX.Element
}

export const UserAvatar = ({
  id,
  model,
  size = 'default',
  type = 'full',
  tag,
  tagIcon,
}: Props) => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const user = model ? model : useAppSelector(selectUserById(id ?? 0))

  if (!user) {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: LocalizationKeys.Components.User.Avatar.DeletedUser,
        })}
      >
        <Avatar
          size={size == 'large' ? 60 : 'default'}
          style={{
            color: '#fff',
            backgroundColor: '#5d5df6',
          }}
        >
          <UserOutlined />
        </Avatar>
      </Tooltip>
    )
  }

  return (
    <Space direction="horizontal">
      <Tooltip
        title={`${user.firstName} ${user.lastName ?? ''}`}
        open={type == 'full' ? false : undefined}
      >
        <Avatar
          size={size == 'large' ? 60 : 'default'}
          src={
            currentUser?.id === user.id
              ? currentUser?.profilePicture
              : undefined
          }
          style={{
            color: '#fff',
            backgroundColor:
              currentUser?.id === user.id ? undefined : '#5d5df6',
          }}
        >
          <div style={{ textTransform: 'uppercase' }}>
            {user.firstName && user.firstName.slice(0, 1)}
            {user.lastName && user.lastName.slice(0, 1)}
            {!user.firstName && !user.lastName && '--'}
          </div>
        </Avatar>
      </Tooltip>
      {type == 'full' && (
        <Space direction="vertical" size={0}>
          <Space direction="horizontal">
            <Text>
              {user.firstName} {user.lastName}
            </Text>
          </Space>
          {size == 'large' && (
            <Space>
              {user.email && (
                <a href={`mailto:${user.email}`}>
                  <Text type="secondary">{user.email}</Text>
                </a>
              )}
              {user.phone && (
                <a href={`tel:${user.phone}`}>
                  <Text type="secondary">{user.phone}</Text>
                </a>
              )}
            </Space>
          )}
          {tag && <Tag icon={tagIcon}>{tag}</Tag>}
        </Space>
      )}
    </Space>
  )
}

export type UserAvatarType = { Avatar: typeof UserAvatar }
