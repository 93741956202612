import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd'
import {
  getEventsByDates,
  selectAllEvents,
  updateEvent,
} from '../../reducers/EventReducer'
import {
  getEventStatuses,
  selectEventStatuses,
} from '../../reducers/EventStatusReducer'
import dayjs, { Dayjs } from 'dayjs'
import '@fullcalendar/react/dist/vdom'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { MetaDataKey } from '../../models/MetaData'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Event } from '../../components/event/Event'
import { useEffect, useRef, useState } from 'react'
import { AlelaPermission } from '../../utils/permissions'
import interactionPlugin from '@fullcalendar/interaction'
import { TableParamsWithDates } from '../../models/TableParams'
import { useHasAccess } from '../../components/HasAccess'
import {
  FilterOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { getVenues } from '../../reducers/VenueReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import FullCalendar, { MoreLinkContentArg } from '@fullcalendar/react'
import allLocales from '@fullcalendar/core/locales-all'
import { setCurrentMonth } from '../../reducers/CalendarReducer'
import { Help } from '../../components/help/Help'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { useLang } from '../../i18n/useLang'
import { capitalizeFirstLetter } from '../../utils/helpers/StringHelper'
import {
  EventSettings,
  loadSettings,
  updateSettings,
} from '../../utils/helpers/EventSettingsHelper'
import { HelpArticle } from '../../utils/hooks/useAlelaHelp'
import { SBAsyncSelect } from '../custom-inputs/sb-async-select/SBAsyncSelect'
import { useTenantSettings } from '../../utils/hooks/useTenantSettings'

export const Calendar = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { hasAccess } = useHasAccess()
  const { selectedLocale } = useLang()
  const calendarRef = useRef<any | null>(null)
  const { firstDayCalendar } = useTenantSettings()
  const [searchParams, setSearchParams] = useSearchParams()

  const eventStatuses = useAppSelector(selectEventStatuses())
  const { query, isLoading } = useAppSelector((state) => state.event)
  const { currentMonth } = useAppSelector((state) => state.calendar)

  const [selectedStatus, setSelectedStatus] = useState<number | undefined>(
    undefined
  )
  const [selectedVenues, setSelectedVenues] = useState<number[]>([])
  const [displayFilters, setDisplayFilters] = useState<boolean>(false)
  const [isFirstCheck, setIsFirstCheck] = useState<boolean>(true)

  // Local settings
  const [displaySettings, setDisplaySettings] = useState<boolean>(false)
  const [eventSettings, setEventSettings] = useState<EventSettings>(
    loadSettings()
  )

  /**
   * We're not using selectEvents as we don't want to restrict to reducer `ids` property
   * So we need to filter here according the state selectedStatus
   */
  const events = useSelector(selectAllEvents())
    .filter(
      (event) =>
        (selectedStatus === undefined || event.status === selectedStatus) &&
        (selectedVenues.length === 0 ||
          event.venues?.some((i) => selectedVenues.includes(i)))
    )
    .map((item) => ({
      id: String(item.id),
      title: item.name,
      start: dayjs(item.start_date).format('YYYY-MM-DD'),
      end: dayjs(item.end_date).format('YYYY-MM-DD'),
      extendedProps: { sourceEvent: item },
      color: 'transparent',
    }))

  const next = () => {
    if (calendarRef.current != null) {
      calendarRef.current.getApi().next()
      updateCurrentMonth(calendarRef.current.getApi().getDate())
    }
  }
  const prev = () => {
    if (calendarRef.current != null) {
      calendarRef.current.getApi().prev()
      updateCurrentMonth(calendarRef.current.getApi().getDate())
    }
  }
  const today = () => {
    if (calendarRef.current != null) {
      calendarRef.current.getApi().today()
      updateCurrentMonth(calendarRef.current.getApi().getDate())
    }
  }
  const gotoDate = (date: Date) => {
    if (calendarRef.current != null) {
      calendarRef.current.getApi().gotoDate(date)
      updateCurrentMonth(calendarRef.current.getApi().getDate())
    }
  }

  const updateCurrentMonth = (date: Date) =>
    dispatch(setCurrentMonth(date.toISOString()))

  const handleDateClick = (arg: any) => {
    // dispatch(
    //   setEntity({
    //     entity: SBRMType.event,
    //     action: 'create',
    //     metadata: ,
    //   })
    // )
    addUrlParams(
      { action: 'create', entity: SBRMType.event },
      [{ key: MetaDataKey.selectedDate, value: arg.dateStr }],
      setSearchParams
    )
  }

  const handleEventDrop = (arg: any) => {
    dispatch(
      updateEvent({
        ...arg.event.extendedProps.sourceEvent,
        start_date: arg.event.start,
        end_date: arg.event.end,
      })
    )
  }

  const handleFiltersFormChange = (values: any) => {
    setSelectedStatus(
      values.filterStatus !== 'all' ? values.filterStatus : undefined
    )
    setSelectedVenues(values.venues ?? [])

    const baseQuery = getBaseQuery(
      calendarRef.current.getApi().getDate(),
      calendarRef.current.getApi().getDate()
    )
    const query: TableParamsWithDates = {
      ...baseQuery,
      filters: {
        ...baseQuery.filters,
        status: values.filterStatus !== 'all' ? [values.filterStatus] : null,
        venues: (values.venues ?? []).length ? values.venues : null,
      },
    }
    dispatch(getEventsByDates(query))
  }

  const handleSettingsFormChange = (values: any) => {
    const nextSettings = { ...eventSettings, color: values.color }
    setEventSettings(nextSettings)
    updateSettings(nextSettings)
  }

  const getBaseQuery = (start: any, end: any) => ({
    ...query,
    pagination: { ...query.pagination, pageSize: 1000 },
    start: dayjs(start).subtract(37, 'd').format('YYYY-MM-DD'),
    end: dayjs(end).add(37, 'd').format('YYYY-MM-DD'),
  })

  useEffect(() => {
    if (calendarRef.current != null) {
      dispatch(
        getEventsByDates(
          getBaseQuery(
            calendarRef.current.getApi().getDate(),
            calendarRef.current.getApi().getDate()
          )
        )
      )
    }
    dispatch(
      getEventStatuses({
        ...query,
        pagination: { ...query.pagination, pageSize: 1000 },
      })
    )
    dispatch(
      getVenues({
        ...query,
        pagination: { ...query.pagination, pageSize: 1000 },
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (events.length === 0 || isFirstCheck === false) return
    setIsFirstCheck(false)

    // If there is no event in the current month we move the calendar to the event with the biggest start date
    if (
      events.filter((e) => dayjs(e.start).month() === dayjs().month())
        .length !== 0
    )
      return

    const biggestDateWithEvent = (): Dayjs | undefined => {
      if (events.length === 0) return undefined

      // Sort by start date descending
      const sorted = [...events].sort((a, b) =>
        dayjs(a.start).isAfter(dayjs(b.start)) ? -1 : 1
      )
      console.log(events, sorted)
      return dayjs(sorted[0].start)
    }

    const bdwe = biggestDateWithEvent()
    if (bdwe === undefined) return

    gotoDate(dayjs(bdwe).toDate())
  }, [events.length])

  return (
    <>
      <PageTitle
        title={capitalizeFirstLetter(dayjs(currentMonth).format('MMMM YYYY'))}
        toolbar={
          <Space direction="horizontal">
            {isLoading && <Spin />}
            {dayjs(currentMonth).format('MMMM YYYY') !==
              dayjs(new Date()).format('MMMM YYYY') && (
              <Button onClick={today} className="hide-mobile">
                {intl.formatMessage({
                  id: LocalizationKeys.Page.Calendar.Today,
                })}
              </Button>
            )}
            <Button icon={<LeftOutlined />} onClick={prev} />
            <Button icon={<RightOutlined />} onClick={next} />
            <DatePicker
              className="hide-mobile"
              value={dayjs(currentMonth)}
              picker="month"
              onChange={(date) =>
                date != null ? gotoDate(date.toDate()) : today()
              }
            />

            <Actions actions={['create']} entity={SBRMType.event} />
            <Button
              className="hide-mobile"
              type={displayFilters ? 'default' : 'dashed'}
              icon={<FilterOutlined />}
              onClick={() => setDisplayFilters(!displayFilters)}
            />
            <Button
              className="hide-mobile"
              type={displaySettings ? 'default' : 'dashed'}
              icon={<SettingOutlined />}
              onClick={() => setDisplaySettings(!displaySettings)}
            />
          </Space>
        }
      />
      <Space direction="vertical">
        {displayFilters && (
          <Space style={{ marginBottom: 20 }}>
            <Form
              layout="inline"
              form={form}
              initialValues={{
                sort: 'due_date',
                filterDates: [dayjs().subtract(30, 'd'), dayjs()],
                filterStatus: 'all',
                venues: [],
              }}
              onValuesChange={(_, values) => {
                handleFiltersFormChange(values)
              }}
            >
              <Row>
                <Col>
                  <Form.Item name={'filterStatus'}>
                    <Radio.Group>
                      {eventStatuses.map((status) => (
                        <Radio.Button key={status.id} value={status.id}>
                          {status.name}
                        </Radio.Button>
                      ))}
                      <Radio.Button value="all">
                        {intl.formatMessage({
                          id: LocalizationKeys.Page.Calendar.All,
                        })}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <SBAsyncSelect
                    multiple
                    name="venues"
                    type={SBRMType.venue}
                    style={{ width: 200 }}
                    size="middle"
                    placeholder="Venues"
                  />
                </Col>
              </Row>
            </Form>
          </Space>
        )}
        {displaySettings && (
          <Space style={{ marginBottom: 20 }}>
            <Form
              layout="inline"
              // form={form}
              initialValues={{
                color: eventSettings.color,
              }}
              onValuesChange={(_, values) => {
                handleSettingsFormChange(values)
              }}
            >
              <Row>
                <Col>
                  <Form.Item label={'Event color from'} name="color">
                    <Radio.Group>
                      <Radio.Button value={'venue'}>{'Venue'}</Radio.Button>
                      <Radio.Button value={'status'}>
                        {'Event status'}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        )}
      </Space>
      <Help.Alert
        article={HelpArticle.CreateAndManageYourEvent}
        style={{ marginBottom: 16 }}
      />
      <Card bordered={false}>
        <FullCalendar
          ref={calendarRef}
          initialDate={currentMonth}
          locales={allLocales}
          firstDay={firstDayCalendar}
          locale={selectedLocale}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          editable={hasAccess([AlelaPermission.editEvent])}
          eventDurationEditable={false}
          selectable={hasAccess([AlelaPermission.createEvent])}
          events={events}
          dateClick={
            hasAccess([AlelaPermission.createEvent])
              ? handleDateClick
              : () => {}
          }
          eventDrop={
            hasAccess([AlelaPermission.viewEvent]) ? handleEventDrop : () => {}
          }
          datesSet={(dateInfo) =>
            dispatch(
              getEventsByDates(getBaseQuery(dateInfo.start, dateInfo.end))
            )
          }
          contentHeight={800}
          dayMaxEvents={true}
          eventContent={(arg) => (
            <Event.CalendarCell
              clickable={true}
              id={Number(arg.event.id)}
              colorFrom={eventSettings.color}
            />
          )}
          moreLinkContent={(arg: MoreLinkContentArg) => (
            <Typography.Text style={{ fontSize: 11.9 }}>
              {arg.shortText}{' '}
              {intl.formatMessage({
                id: LocalizationKeys.Page.Calendar.More,
              })}
            </Typography.Text>
          )}
        />
      </Card>
    </>
  )
}

export type CalendarType = { Calendar: typeof Calendar }
