import { schema } from 'normalizr'
import { ContactAssociation } from './Contact'

const userSchema = new schema.Entity('user')
const bookingSchema = new schema.Entity('booking')
const bookingTypeSchema = new schema.Entity('bookingType')
const artistSchema = new schema.Entity('artist')
const eventSchema = new schema.Entity('event')
const eventStatusSchema = new schema.Entity('eventStatus')
const venueSchema = new schema.Entity('venue')
const venueTypeSchema = new schema.Entity('venueType')
const stageSchema = new schema.Entity('stage')
const hotelSchema = new schema.Entity('hotel')
const roomSchema = new schema.Entity('room')
const invoiceSchema = new schema.Entity('invoice')
const paymentSchema = new schema.Entity('payment')
const riderSchema = new schema.Entity('rider')
const riderStatusSchema = new schema.Entity('riderStatus')
const riderTypeSchema = new schema.Entity('riderType')
const contractSchema = new schema.Entity('contract')
const contractStatusSchema = new schema.Entity('contractStatus')
const performanceSchema = new schema.Entity('performance')
const contactSchema = new schema.Entity('contact')
const contactTypeSchema = new schema.Entity('contactType')
const performanceTypeSchema = new schema.Entity('performanceType')
const contactAssociationSchema = new schema.Entity('contactAssociation')
const fileSchema = new schema.Entity('file')
const fileTypeSchema = new schema.Entity('fileType')
const travelSchema = new schema.Entity('travel')
const travelTypeSchema = new schema.Entity('travelType')
const groundSchema = new schema.Entity('ground')
const stationSchema = new schema.Entity('station')
const stationTypeSchema = new schema.Entity('stationType')
const publicLinkSchema = new schema.Entity('publicLink')
const roleSchema = new schema.Entity('role')
const permissionSchema = new schema.Entity('permission')
const addressSchema = new schema.Entity('address')
const invoiceCategorySchema = new schema.Entity('invoiceCategory')
const supplierSchema = new schema.Entity('supplier')
const supplierTypeSchema = new schema.Entity('supplierType')
const invitationSchema = new schema.Entity('invitation')
const notificationSchema = new schema.Entity('notification')
const commentSchema = new schema.Entity('comment')
const externalCommentSchema = new schema.Entity('externalComment')
const reportSchema = new schema.Entity('report')
const reportItemSchema = new schema.Entity('reportItem')
const taskSchema = new schema.Entity('task')
const taskStatusSchema = new schema.Entity('taskStatus')
const externalAdvancingLinkSchema = new schema.Entity('externalAdvancingLink')
const externalUserSchema = new schema.Entity('externalUser')
const currencySchema = new schema.Entity('currency')
const marketingTaskSchema = new schema.Entity('marketingTask')
const marketingTaskStatusSchema = new schema.Entity('marketingTaskStatus')

userSchema.define({
  contact: contactSchema,
})
externalUserSchema.define({
  //
})

bookingSchema.define({
  artist: artistSchema,
  event: eventSchema,
  rooms: [roomSchema],
  invoices: [invoiceSchema],
  riders: [riderSchema],
  performances: [performanceSchema],
  owner: contactSchema,
  type: bookingTypeSchema,
  contacts: [contactSchema],
  association: contactTypeSchema,
  currency: currencySchema,
  file: fileSchema,
})

artistSchema.define({
  bookings: [bookingSchema],
  contacts: [contactSchema],
})

eventSchema.define({
  bookings: [bookingSchema],
  stages: [stageSchema],
  venues: [venueSchema],
  invoices: [invoiceSchema],
  performances: [performanceSchema],
  status: eventStatusSchema,
  contacts: [contactSchema],
  files: [fileSchema],
  report: reportSchema,
})

eventStatusSchema.define({
  visibleByRoles: [roleSchema],
})

venueSchema.define({
  type: venueTypeSchema,
  stages: [stageSchema],
  contacts: [contactSchema],
})

venueTypeSchema.define({
  venues: [venueSchema],
})

stageSchema.define({
  venue: venueSchema,
  performances: [performanceSchema],
})

hotelSchema.define({
  rooms: [roomSchema],
})

roomSchema.define({
  hotel: hotelSchema,
  booking: bookingSchema,
  contacts: [contactSchema],
  currency: currencySchema,
  roomCreditCurrency: currencySchema,
})

invoiceSchema.define({
  booking: bookingSchema,
  event: eventSchema,
  files: [fileSchema],
  category: invoiceCategorySchema,
  payments: [paymentSchema],
  supplier: supplierSchema,
  currency: currencySchema,
})

paymentSchema.define({
  invoice: invoiceSchema,
  pop: fileSchema,
  currency: currencySchema,
})

supplierSchema.define({
  invoices: [invoiceSchema],
  type: supplierTypeSchema,
  contacts: [contactSchema],
})

riderSchema.define({
  booking: bookingSchema,
  status: riderStatusSchema,
  type: riderTypeSchema,
  file: fileSchema,
})

riderStatusSchema.define({
  riders: [riderSchema],
})
riderTypeSchema.define({
  riders: [riderSchema],
})

contractSchema.define({
  booking: bookingSchema,
  status: contractStatusSchema,
  file: fileSchema,
})

contractStatusSchema.define({
  contracts: [contractSchema],
})

performanceTypeSchema.define({})
performanceSchema.define({
  booking: bookingSchema,
  stage: stageSchema,
  type: performanceTypeSchema,
})

contactSchema.define({
  associations: [contactAssociationSchema],
  bookings: [bookingSchema],
  artists: [artistSchema],
  venues: [venueSchema],
  events: [eventSchema],
})

contactAssociationSchema.define({
  type: contactTypeSchema,
  contact: contactSchema,
  contactable: new schema.Union(
    {
      artist: artistSchema,
      venue: venueSchema,
      booking: bookingSchema,
      event: eventSchema,
      supplier: supplierSchema,
    },
    (value: any, parent: ContactAssociation, key: string) => {
      return parent.contactableType!
    }
  ),
})

fileSchema.define({
  type: fileTypeSchema,
  event: eventSchema,
  booking: bookingSchema,
  publicLinks: [publicLinkSchema],
})

travelSchema.define({
  departure: stationSchema,
  arrival: stationSchema,
  type: travelTypeSchema,
  booking: bookingSchema,
  contacts: [contactSchema],
})

groundSchema.define({
  booking: bookingSchema,
  from: {
    venue: venueSchema,
    hotel: hotelSchema,
    travel: travelSchema,
    customaddress: { address: addressSchema },
  },
  to: {
    venue: venueSchema,
    hotel: hotelSchema,
    travel: travelSchema,
    customaddress: { address: addressSchema },
  },
  driver: contactSchema,
  passengers: [contactSchema],
})

stationSchema.define({
  type: stationTypeSchema,
})

permissionSchema.define({
  roles: [roleSchema],
})

publicLinkSchema.define({
  //
})

invitationSchema.define({
  //
})

notificationSchema.define({
  //
})

commentSchema.define({
  user: userSchema,
  mentionedUsers: [userSchema],
})

externalCommentSchema.define({
  internalUser: userSchema,
  externalUser: externalUserSchema,
  mentionedUsers: [userSchema],
})

reportSchema.define({})

reportItemSchema.define({})

taskSchema.define({
  status: taskStatusSchema,
  assignee: userSchema,
  creator: userSchema,
})
taskStatusSchema.define({})

currencySchema.define({})

marketingTaskSchema.define({
  status: marketingTaskStatusSchema,
  user: userSchema,
  files: [fileSchema],
})

marketingTaskStatusSchema.define({
  tasks: [marketingTaskSchema],
})

export {
  userSchema,
  externalUserSchema,
  bookingSchema,
  bookingTypeSchema,
  artistSchema,
  eventSchema,
  eventStatusSchema,
  venueSchema,
  venueTypeSchema,
  stageSchema,
  hotelSchema,
  roomSchema,
  invoiceSchema,
  paymentSchema,
  riderSchema,
  riderTypeSchema,
  riderStatusSchema,
  contractSchema,
  contractStatusSchema,
  performanceSchema,
  performanceTypeSchema,
  contactSchema,
  contactTypeSchema,
  contactAssociationSchema,
  fileSchema,
  fileTypeSchema,
  travelSchema,
  travelTypeSchema,
  groundSchema,
  stationSchema,
  stationTypeSchema,
  publicLinkSchema,
  roleSchema,
  permissionSchema,
  addressSchema,
  invoiceCategorySchema,
  supplierSchema,
  supplierTypeSchema,
  invitationSchema,
  notificationSchema,
  commentSchema,
  externalCommentSchema,
  reportSchema,
  reportItemSchema,
  taskSchema,
  taskStatusSchema,
  externalAdvancingLinkSchema,
  currencySchema,
  marketingTaskSchema,
  marketingTaskStatusSchema,
}
