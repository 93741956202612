import { useAppSelector } from '../../reducers/hooks'
import { ContactAssociation } from '../../models/Contact'
import { selectContactAssociationById } from '../../reducers/ContactAssociationReducer'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { Contact } from './Contact'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { useSearchParams } from 'react-router-dom'

interface Props {
  id?: number
  model?: ContactAssociation
}

export const Association = ({ id, model }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const association = model
    ? model
    : useAppSelector(selectContactAssociationById(id!))

  const handleDelete = () => {
    // dispatch(setSelectedContactAssociation(association?.id ?? 0))
    // dispatch(
    //   setEntity({
    //     entity: SBRMType.contactAssociation,
    //     action: 'delete',
    //   })
    // )
    addUrlParams(
      {
        action: 'delete',
        entity: SBRMType.contactAssociation,
        entityId: (association?.id ?? 0).toString(),
      },
      undefined,
      setSearchParams
    )
  }
  return association ? (
    <Contact.TypeTag
      id={association.type}
      closable={true}
      onClose={() => handleDelete()}
    />
  ) : (
    <></>
  )
}

export type AssociationType = { Association: typeof Association }
