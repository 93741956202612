import { Col, Row, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { Travel } from '../../../components/travel/Travel'
import { Travel as TravelModel } from '../../../models/Travel'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import {
  getTravels,
  selectTravels,
  setTravelQuery,
} from '../../../reducers/TravelReducer'
import HasAccess from '../../../components/HasAccess'
import { AlelaPermission } from '../../../utils/permissions'
import { Booking } from '../../../components/booking/Booking'
import SBTable from '../../../components/SBTable'
import { Contact } from '../../../components/contact/Contact'
import { selectBookingsByIds } from '../../../reducers/BookingReducer'
import { getIdsFromArray } from '../../../utils/helpers/ReducerHelper'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { selectArtistsByIds } from '../../../reducers/ArtistReducer'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'
import DescriptionModal from '../../../components/DescriptionModal'

const TravelsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const bookingsIdsFromTravels = (travels: TravelModel[]) =>
    getIdsFromArray(travels, (travel) => travel.booking)

  const event = useAppSelector(selectSelectedEvent())
  const travels = useAppSelector(selectTravels())
  const bookings = useAppSelector(
    selectBookingsByIds(bookingsIdsFromTravels(travels))
  )

  const artists = useAppSelector(
    selectArtistsByIds(bookings.map((b) => b.artist ?? 0))
  )

  const { query, isLoading } = useAppSelector((state) => state.travel)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<TravelModel> = [
    {
      key: 'bookings',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Travels.Booking,
      }),
      width: 200,
      render: (record: TravelModel) => (
        <Booking.Avatar id={record.booking ?? 0} />
      ),
      filters: bookings.map((booking) => ({
        text: artists.find((a) => a.id === booking.artist)
          ? artists.find((a) => a.id === booking.artist)!.name
          : '',
        value: booking.id,
      })),
    },
    {
      key: 'type',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Travels.Type,
      }),
      render: (record: TravelModel) => <Travel.Type id={record.id} />,
    },
    {
      key: 'travel',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Travels.Travel,
      }),
      render: (record: TravelModel) => <Travel.Infos id={record.id} />,
    },
    {
      key: 'contacts',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Travels.Travelers,
      }),
      align: 'center',
      render: (record: TravelModel) => (
        <Contact.AvatarGroup ids={record.contacts} />
      ),
    },
    {
      key: 'notes',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Grounds.Notes,
      }),
      render: (record: TravelModel) =>
        record.notes && (
          <DescriptionModal
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Grounds.GroundNotes,
            })}
            content={record.notes}
          />
        ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: TravelModel) => (
        <Actions
          actions={['update', 'duplicate', 'delete']}
          entity={SBRMType.travel}
          entityId={record.id}
          metadata={[{ key: MetaDataKey.eventId, value: event?.id }]}
        />
      ),
    },
  ]

  const setQueryAndFetch = (q?: TableParams) => {
    if (q !== undefined) dispatch(setTravelQuery(q))
    dispatch(getTravels(q ?? query))
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TravelModel> | SorterResult<TravelModel>[]
  ) => {
    const newQuery = {
      pagination,
      filters: { ...filters, ...{ events: [event?.id!] } },
      ...extractSortDataFromSorterResult(sorter),
    }
    setQueryAndFetch(newQuery)
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event?.id!] },
      columnKey: 'time',
      order: 'ascend',
    }
    setQueryAndFetch(baseQuery)
  }, [SBRMIsOpen])

  if (event === undefined) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listTravel]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Travels.Title,
            })}
            level={5}
            badge={travels.length}
            toolbar={
              <Space>
                <Actions
                  actions={['create']}
                  entity={SBRMType.travel}
                  metadata={[{ key: MetaDataKey.eventId, value: event.id }]}
                />
                <Booking.Export eventId={event.id} entity={SBRMType.travel} />
                <Help.Button article={HelpArticle.AddAndManageTravels} />
              </Space>
            }
          />
          <Travel.ExternalRequests
            eventId={event?.id}
            shouldRefreshList={() => {
              setQueryAndFetch()
            }}
          />
          <SBTable
            entity={SBRMType.travel}
            emptyActionsMetadata={[
              { key: MetaDataKey.eventId, value: event.id },
            ]}
            scroll={{ x: 1000 }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={travels}
            loading={isLoading && !travels.length}
            pagination={query.pagination}
            onChange={handleTableChange}
          />
        </HasAccess>
      </Col>
    </Row>
  )
}

export default TravelsPage
