import {
  getContacts,
  selectContacts,
  setContactQuery,
} from '../../reducers/ContactReducer'
import {
  SBRMType,
  SBRMContactAssociationType,
} from '../../modules/sbrm/SBRMModel'
import { Col, Row } from 'antd'
import { useEffect } from 'react'
import HasAccess from '../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { TableParams } from '../../models/TableParams'
import { AlelaPermission } from '../../utils/permissions'
import { Contact } from '../../components/contact/Contact'
import { initialQuery } from '../../utils/helpers/crud/models'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { Event } from '../../components/event/Event'
import { MetaDataKey } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

const EventContactsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const contacts = useAppSelector(selectContacts())
  const event = useAppSelector(selectSelectedEvent())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const fetchContacts = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { events: [event?.id!] },
      with: ['associations'],
    }
    dispatch(setContactQuery(baseQuery))
    dispatch(getContacts(baseQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen || !event) {
      return
    }
    fetchContacts()
  }, [dispatch, SBRMIsOpen])

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listContactAssociation]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Contacts,
            })}
            level={5}
            badge={contacts.length}
            toolbar={
              <Actions
                actions={['create']}
                entity={SBRMType.contactAssociation}
                metadata={[
                  {
                    key: MetaDataKey.contactAssociationType,
                    value: SBRMContactAssociationType.event,
                  },
                  { key: MetaDataKey.eventId, value: event?.id },
                ]}
              />
            }
          />
          <Row gutter={[16, 16]}>
            <Col md={24} sm={24} xs={24}>
              {!contacts.length &&
                event?.venues?.map((venue, index) => (
                  <Event.AutoImportVenueContacts
                    key={index}
                    eventId={event.id}
                    venueId={venue}
                    onImportFinished={() => {
                      fetchContacts()
                    }}
                  />
                ))}
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Contact.TableWithAssociation
                emptyActionsMetadata={[
                  {
                    key: MetaDataKey.contactAssociationType,
                    value: SBRMContactAssociationType.event,
                  },
                  { key: MetaDataKey.eventId, value: event?.id },
                ]}
              />
            </Col>
          </Row>
        </HasAccess>
      </Col>
    </Row>
  )
}

export default EventContactsPage
