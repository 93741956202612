import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, InputRef, Space, Tag, Typography } from 'antd'
import { selectEventById } from '../../reducers/EventReducer'
import { useAppSelector } from '../../reducers/hooks'
import dayjs from 'dayjs'
import { Event } from '../../models/Event'
import TextArea from 'antd/es/input/TextArea'
import { PlusOutlined } from '@ant-design/icons'
const { Text } = Typography

interface Props {
  id?: number
  model?: Event
}

export const KanbanQuickForm = ({ id, model }: Props) => {
  const event = model ? model : useAppSelector(selectEventById(id!))
  const [createMode, setCreateMode] = useState<boolean>(false)
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    createMode &&
      inputRef.current &&
      inputRef.current!.focus({
        cursor: 'start',
      })
  }, [createMode])
  return (
    <>
      {createMode ? (
        <TextArea
          ref={inputRef}
          placeholder="Title"
          variant="filled"
          onPressEnter={() => setCreateMode(!createMode)}
          allowClear={true}
        />
      ) : (
        <Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setCreateMode(!createMode)}
        >
          Add a task
        </Button>
      )}
    </>
  )
}

export type KanbanQuickFormType = { QuickForm: typeof KanbanQuickForm }
