import {
  importContactsFromArtist,
  selectBookingById,
} from '../../reducers/BookingReducer'
import {
  getContacts,
  selectContacts,
  setContactQuery,
} from '../../reducers/ContactReducer'
import { Col, Row } from 'antd'
import { Booking } from './Booking'
import { useEffect, useState } from 'react'
import { Contact } from '../contact/Contact'
import { SBBanner } from '../banner/SBBanner'
import { DownloadOutlined } from '@ant-design/icons'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../utils/helpers/crud/models'
import { selectArtistById } from '../../reducers/ArtistReducer'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { MetaData } from '../../models/MetaData'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'

interface Props {
  bookingId: number
  emptyActionsMetadata?: MetaData[]
  onImportFinished: () => void
}

const ContactsTable = ({
  bookingId,
  emptyActionsMetadata,
  onImportFinished,
}: Props) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [importInProgress, setImportInProgress] = useState<boolean>(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const booking = useAppSelector(selectBookingById(bookingId))
  const artist = useAppSelector(selectArtistById(booking?.artist ?? 0))
  const contacts = useAppSelector(selectContacts())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)
  const { isLoading } = useAppSelector((state) => state.contact)

  const fetchContacts = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { bookings: [bookingId] },
      with: ['associations'],
    }
    dispatch(setContactQuery(baseQuery))
    dispatch(getContacts(baseQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    fetchContacts()
  }, [dispatch, SBRMIsOpen])

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {/* If there is no contacts, propose to imports artist contacts with their roles */}
        {contacts.length == 0 &&
        !isLoading &&
        artist &&
        artist.contacts &&
        artist.contacts.length ? (
          <SBBanner.WithAction1
            content={intl.formatMessage({
              id: LocalizationKeys.Components.Booking.ContactsTable
                .ContactsFound,
            })}
            buttonIcon={<DownloadOutlined />}
            buttonTitle={intl.formatMessage({
              id: LocalizationKeys.Misc.Form.Import,
            })}
            buttonLoading={importInProgress}
            onButtonClick={() => {
              setImportInProgress(true)
              dispatch(importContactsFromArtist(booking!)).then(() => {
                onImportFinished()
                setImportInProgress(false)
                fetchContacts()
              })
            }}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col span={24}>
        {/* 
            If there are contacts attached to either travels, rooms or ground that are not present propose to import them 
            As their is no role on these associations, we need to push to the contactAssociation creation
        */}
        <Booking.MissingContactsInPartyCrew id={bookingId} />
      </Col>
      <Col span={24}>
        <Contact.TableWithAssociation
          emptyActionsMetadata={emptyActionsMetadata}
        />
      </Col>
    </Row>
  )
}

type ContactsTableType = { ContactsTable: typeof ContactsTable }

export { ContactsTable, type ContactsTableType }
