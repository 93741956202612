import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { EVENTS_URL, MARKETING_TASK_STATUS_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { marketingTaskStatusSchema } from '../models/schema'
import { MarketingTaskStatus } from '../models/MarketingTask'

const initialState: SBState<MarketingTaskStatus> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'marketingTaskStatus',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getMarketingTaskStatusesSuccess,
  setQuery: setMarketingTaskStatusQuery,
  reset: resetMarketingTaskStatusState,
  resetQueryAndIds: resetMarketingTaskStatusQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: MarketingTaskStatus }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectMarketingTaskStatuses = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedMarketingTaskStatus = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectMarketingTaskStatusById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectMarketingTaskStatusesByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const selectAllMarketingTaskStatus = () =>
  createSelector([selectRawItems], (items) =>
    Object.entries(items).map((i) => i[1])
  )

export const setSelectedMarketingTaskStatus =
  (marketingTaskStatus: MarketingTaskStatus | number) =>
  async (dispatch: AppDispatch) =>
    dispatch(
      slice.actions.setSelectedId(
        getIdOrModelId<MarketingTaskStatus>(marketingTaskStatus)
      )
    )

export const getMarketingTaskStatuses = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<MarketingTaskStatus>(
    MARKETING_TASK_STATUS_URL,
    params,
    [marketingTaskStatusSchema],
    slice.actions
  )

export const getMarketingTaskStatusWithId = (id: number) =>
  SBAPIFetchDispatch<MarketingTaskStatus>(
    `${MARKETING_TASK_STATUS_URL}/${id}`,
    marketingTaskStatusSchema,
    slice.actions
  )

export const createMarketingTaskStatus = (
  marketingTaskStatus: MarketingTaskStatus
) =>
  SBAPICreate<MarketingTaskStatus>(
    marketingTaskStatus,
    MARKETING_TASK_STATUS_URL,
    marketingTaskStatusSchema,
    slice.actions
  )

export const updateMarketingTaskStatus = (
  marketingTaskStatus: MarketingTaskStatus
) =>
  SBAPIUpdate<MarketingTaskStatus>(
    marketingTaskStatus,
    `${MARKETING_TASK_STATUS_URL}/${marketingTaskStatus.id}`,
    slice.actions
  )

export const restoreMarketingTaskStatus = (
  marketingTaskStatus: MarketingTaskStatus
) =>
  SBAPIUpdate<MarketingTaskStatus>(
    marketingTaskStatus,
    `${MARKETING_TASK_STATUS_URL}/${marketingTaskStatus.id}/restore`,
    slice.actions
  )

export const deleteMarketingTaskStatus = (
  marketingTaskStatus: MarketingTaskStatus
) =>
  SBAPIDelete<MarketingTaskStatus>(
    marketingTaskStatus,
    `${MARKETING_TASK_STATUS_URL}/${marketingTaskStatus.id}`,
    slice.actions
  )

export const getEventMarketingWithId = (id: number) =>
  SBAPIFetchDispatch<MarketingTaskStatus[]>(
    `${EVENTS_URL}/${id}/marketing`,
    [marketingTaskStatusSchema],
    slice.actions
  )
