import {
  getContracts,
  selectContracts,
  setContractQuery,
} from '../../../reducers/ContractReducer'
import { useEffect, useState } from 'react'
import { Col, Row, Space, Spin } from 'antd'
import SBTable from '../../../components/SBTable'
import { File } from '../../../components/file/File'
import HasAccess from '../../../components/HasAccess'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { AlelaPermission } from '../../../utils/permissions'
import { Booking } from '../../../components/booking/Booking'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { Contract as ContractModel } from '../../../models/Contract'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { ContractStatus } from '../../../components/contract-status/ContractStatus'
import DescriptionModal from '../../../components/DescriptionModal'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useSearchParams } from 'react-router-dom'
import { addUrlParams } from '../../../modules/sbrm/UrlHelper'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'
import { Contract } from '../../../components/contract/Contract'

const ContractsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const event = useAppSelector(selectSelectedEvent())
  const contracts = useAppSelector(selectContracts())
  const { query, isLoading } = useAppSelector((state) => state.contract)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<ContractModel> = [
    {
      key: 'booking',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Contracts.Booking,
      }),
      width: 200,
      render: (record: ContractModel) => (
        <a href="#" onClick={(e) => e.stopPropagation()}>
          <Booking.Avatar id={record.booking ?? 0} />
        </a>
      ),
    },
    {
      key: 'status',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Contracts.Status,
      }),
      render: (record: ContractModel) => (
        <ContractStatus.Tag id={record.status} />
      ),
    },
    {
      key: 'notes',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Contracts.Notes,
      }),
      align: 'center',
      render: (record: ContractModel) =>
        record.notes && (
          <a href="#" onClick={(e) => e.stopPropagation()}>
            <DescriptionModal title="Notes" content={record.notes} />
          </a>
        ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: ContractModel) => (
        <a href="#" onClick={(e) => e.stopPropagation()}>
          <Space direction="horizontal">
            <File.DownloadButton id={record.file} />
            <Actions
              actions={['update', 'comment', 'delete']}
              entity={SBRMType.contract}
              entityId={record.id}
            />
          </Space>
        </a>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ContractModel> | SorterResult<ContractModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters: { ...query.filters, ...filters },
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setContractQuery(newQuery))
    dispatch(getContracts(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    const baseQuery: TableParams = {
      ...initialQuery,
      filters: { events: [event?.id!] },
    }

    dispatch(setContractQuery(baseQuery))
    dispatch(getContracts(baseQuery))
  }, [SBRMIsOpen])

  if (event === undefined) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listContract]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Contracts.Title,
            })}
            level={5}
            badge={contracts.length}
            toolbar={
              <Space>
                <Actions
                  actions={['create']}
                  entity={SBRMType.contract}
                  metadata={[{ key: MetaDataKey.eventId, value: event.id }]}
                />
                <Help.Button article={HelpArticle.AddContractOnEvent} />
              </Space>
            }
          />
          <Contract.ExternalRequests
            eventId={event.id}
            shouldRefreshList={() => {
              dispatch(getContracts(query))
            }}
          />
          <SBTable
            entity={SBRMType.contract}
            className="table-row-clickable"
            emptyActionsMetadata={[
              { key: MetaDataKey.eventId, value: event?.id },
            ]}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={contracts}
            loading={isLoading && !contracts.length}
            pagination={query.pagination}
            onChange={handleTableChange}
            onRow={(record, rowIndex) => ({
              onClick: (event) =>
                addUrlParams(
                  {
                    action: 'view',
                    entity: SBRMType.contract,
                    entityId: record.id.toString(),
                  },
                  undefined,
                  setSearchParams
                ),
            })}
          />
        </HasAccess>
      </Col>
    </Row>
  )
}

export default ContractsPage
