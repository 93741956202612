import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Lane } from '../../models/Kanban'
import { LaneMap } from './types'
import { composeColumnId } from './utils'
import { once } from 'lodash'

type KanbanContextProps<T> = {
  columns: LaneMap<T>
  ordered: string[]
  setColumns: Dispatch<SetStateAction<LaneMap<T>>>
  setOrdered: Dispatch<SetStateAction<string[]>>
  setKanbanData: (data: Lane<T>[]) => void
  renderItem: (item: T) => React.ReactNode
  columnToolbar: (columnId: number) => React.ReactNode
  itemMoved: (
    draggableId: string,
    newColumnId: number,
    newIndex: number
  ) => void
}

const initKanbanContextPropsState = <T,>(): KanbanContextProps<T> => ({
  columns: {},
  ordered: [],
  setColumns: () => {},
  setOrdered: () => {},
  setKanbanData: () => {},
  renderItem: (item: T) => <></>,
  columnToolbar: (columnId) => <></>,
  itemMoved: (draggableId, newColumnId, newIndex) => {},
})

const createKanbanContext = once(<T,>() =>
  createContext<KanbanContextProps<T>>(initKanbanContextPropsState<T>())
)
const useKanban = <T,>() => useContext(createKanbanContext<T>())

interface KanbanContextProviderProps<T> {
  initialValue: Lane<T>[]
  renderItem: KanbanContextProps<T>['renderItem']
  itemMoved: KanbanContextProps<T>['itemMoved']
  columnToolbar: KanbanContextProps<T>['columnToolbar']
}

const KanbanContextProvider = <T,>({
  children,
  initialValue,
  renderItem,
  itemMoved,
  columnToolbar,
}: PropsWithChildren<KanbanContextProviderProps<T>>) => {
  const KanbanContext = createKanbanContext<T>()
  const [columns, setColumns] = useState<LaneMap<T>>({})
  const [ordered, setOrdered] = useState<string[]>([])

  const setKanbanData = (data: Lane<T>[]) => {
    setColumns(
      data.reduce((a, v) => ({ ...a, [composeColumnId(v.id)]: v }), {})
    )
    setOrdered(data.map((e) => composeColumnId(e.id)))
  }

  useEffect(() => {
    setKanbanData(initialValue)
  }, [])

  return (
    <KanbanContext.Provider
      value={{
        columns,
        ordered,
        setColumns,
        setOrdered,
        setKanbanData,
        renderItem,
        itemMoved,
        columnToolbar,
      }}
    >
      {children}
    </KanbanContext.Provider>
  )
}

type KanbanContextProviderType = typeof KanbanContextProvider

export {
  KanbanContextProvider,
  type KanbanContextProviderType,
  type KanbanContextProviderProps,
  useKanban,
}
