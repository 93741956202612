import { useIntl } from 'react-intl'
import { Button, Card, DatePicker, Form, FormInstance, Input } from 'antd'
import { requiredRule, stringRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { useEffect, useState } from 'react'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { SBAsyncSelect } from '../../../components/custom-inputs/sb-async-select/SBAsyncSelect'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { selectSelectedTask } from '../../../reducers/TaskReducer'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import { useSBRMData } from '../../../modules/sbrm/hooks'
import { selectSelectedMarketingTask } from '../../../reducers/MarketingTaskReducer'
import { File } from '../../file/File'
import {
  ArrowLeftOutlined,
  FileOutlined,
  PictureOutlined,
} from '@ant-design/icons'
import { EVENTS_URL, MARKETING_TASK_URL } from '../../../utils/urls'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const { metadata } = useSBRMData(false)
  const item = useAppSelector(selectSelectedMarketingTask())
  const [showDropZone, setShowDropZone] = useState<boolean>(false)

  const handleDragOn = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setShowDropZone(true)
    }
  }

  const handleDragOff = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragleave') {
      setShowDropZone(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue(item)
    item?.dueDate && form.setFieldValue('dueDate', dayjs(item?.dueDate))
  }, [item])

  if (item === undefined) {
    return <></>
  }

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item
          name="id"
          rules={[requiredRule(intl)]}
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="order"
          rules={[requiredRule(intl)]}
          style={{ display: 'none' }}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="title"
          label={intl.formatMessage({ id: LocalizationKeys.Misc.Form.Title })}
          rules={[requiredRule(intl), stringRule(intl)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Description,
          })}
          rules={[stringRule(intl)]}
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name="dueDate"
          rules={[]}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.DueDate,
          })}
        >
          <DatePicker format="YYYY-MM-DD" className="w-full" />
        </Form.Item>
        <SBAsyncSelect
          type={SBRMType.marketingTaskStatus}
          name={'status'}
          rules={[requiredRule(intl)]}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Status,
          })}
        />
        <SBAsyncSelect
          type={SBRMType.user}
          name={'user'}
          rules={[]}
          label={intl.formatMessage({
            id: LocalizationKeys.Misc.Form.Assignee,
          })}
        />
      </Form>
      Files
      <br />
      <Card
        onDragOver={handleDragOn}
        onDragLeave={handleDragOff}
        bordered={false}
      >
        {!showDropZone && (
          <>
            <Button type="text" onClick={() => setShowDropZone(true)}>
              Click here or drag a file
            </Button>
            <File.Grid ids={item.files ?? []} withDeleteButton={true} />
          </>
        )}
        {showDropZone && (
          <>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={() => setShowDropZone(false)}
            >
              {'Back to files list'}
            </Button>
            <File.Upload
              multiple
              icon={<FileOutlined />}
              title={intl.formatMessage({
                id: LocalizationKeys.Components.Event.Cover,
              })}
              action={`${MARKETING_TASK_URL}/${item.id}/files`}
              accept={'*/*'}
              onSuccessUpload={() => {}}
            />
          </>
        )}
      </Card>
    </>
  )
}

export default Update
