import React from 'react'
import { AlelaPermission } from '../utils/permissions'
import HasAccess from '../components/HasAccess'

const MarketingPage = () => {
  return (
    <HasAccess permissions={[AlelaPermission.listMarketingTask]}>
      <>Marketing Master Page</>
    </HasAccess>
  )
}

export default MarketingPage
