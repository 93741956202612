import { Draggable } from 'react-beautiful-dnd'
import type {
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import List from './List'
import styled from 'styled-components'
import { Badge, Card, Space, Typography } from 'antd'
import { KanbanQuickForm } from './QuickForm'
import { Lane } from '../../models/Kanban'
import { composeColumnId } from './utils'
import { useKanban } from './context'

const { Text } = Typography

const Container = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
`

type ColumnProps<T> = {
  lane: Lane<T>
  index: number
  isScrollable?: boolean
  isCombineEnabled?: boolean
  useClone?: boolean
}

const Column = <T,>({
  index,
  lane,
  isScrollable,
  isCombineEnabled,
  useClone,
}: ColumnProps<T>) => {
  const { columnToolbar } = useKanban()
  const columnId = composeColumnId(lane.id)

  return (
    <Draggable index={index} draggableId={columnId}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Card
            styles={{
              header: { padding: '0 10px 0 15px' },
              body: { padding: 10, paddingTop: 0 },
            }}
            style={{
              borderColor: snapshot.isDragging ? '#5e5df6' : 'transparent',
            }}
            title={
              <Space
                className="w-full"
                style={{ justifyContent: 'space-between' }}
              >
                <Space>
                  <Badge count={lane.cards.length} />
                  <Text
                    {...provided.dragHandleProps}
                    aria-label={`${lane.title} tasks list`}
                  >
                    {lane.title}
                  </Text>
                </Space>
                {columnToolbar(lane.id)}
              </Space>
            }
          >
            <List
              listId={columnId}
              listType="QUOTE"
              cards={lane.cards}
              internalScroll={isScrollable}
              isCombineEnabled={Boolean(isCombineEnabled)}
              useClone={Boolean(useClone)}
            />
            {/* <KanbanQuickForm /> */}
          </Card>
        </Container>
      )}
    </Draggable>
  )
}

export default Column
