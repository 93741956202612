import { Card, Space, Tag, Tooltip, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { MarketingTask } from '../../models/MarketingTask'
import { selectMarketingTaskById } from '../../reducers/MarketingTaskReducer'
import {
  ClockCircleOutlined,
  FileOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { ContactAvatarGroup } from '../contact/AvatarGroup'
import relativeTime from 'dayjs/plugin/relativeTime'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useSearchParams } from 'react-router-dom'
import { addUrlParams } from '../../modules/sbrm/UrlHelper'
import { User } from '../user/User'

const { Text, Paragraph } = Typography

interface Props {
  id?: number
  model?: MarketingTask
}

export const MarketingKanbanCard = ({ id, model }: Props) => {
  dayjs.extend(relativeTime)
  const [searchParams, setSearchParams] = useSearchParams()
  const task = model ? model : useAppSelector(selectMarketingTaskById(id ?? 0))

  if (task === undefined) return <></>

  return (
    <Card
      styles={{ body: { padding: 10 } }}
      className="w-full"
      style={{ borderColor: false ? '#5e5df6' : undefined }}
      onClick={() =>
        addUrlParams(
          {
            action: 'update',
            entity: SBRMType.marketingTask,
            entityId: task.id.toString(),
          },
          undefined,
          setSearchParams
        )
      }
      cover={
        false ? (
          <img
            alt="example"
            height={150}
            width={'100%'}
            style={{ objectFit: 'cover' }}
            src={'task.cover'}
          />
        ) : undefined
      }
    >
      <Space direction="vertical" className="w-full" size={6}>
        <Paragraph
          className="m-0"
          style={{ wordBreak: 'keep-all', width: '100%' }}
          ellipsis={{ rows: 2, expandable: false }}
        >
          {task.title}
        </Paragraph>
        <Space
          direction="horizontal"
          className="w-full"
          style={{ justifyContent: 'space-between' }}
        >
          <Space direction="horizontal" size={10}>
            {(task.commentsCount ?? 0) > 0 && (
              <Text type="secondary">
                {task.commentsCount} <MessageOutlined />
              </Text>
            )}
            {(task.attachementsCount ?? 0) > 0 && (
              <Text type="secondary">
                {task.attachementsCount} <FileOutlined />
              </Text>
            )}
            {task.dueDate && (
              <Tooltip title={dayjs(task.dueDate).fromNow()}>
                <Tag
                  color={
                    dayjs().diff(task.dueDate, 'day') < -5
                      ? 'green'
                      : dayjs().diff(task.dueDate, 'day') < 0
                      ? 'orange'
                      : 'red'
                  }
                  icon={<ClockCircleOutlined />}
                >
                  {dayjs(task.dueDate).format('DD/MM')}
                </Tag>
              </Tooltip>
            )}
          </Space>
          {task.user && <User.Avatar id={task.user} size={'small'} />}
        </Space>
      </Space>
    </Card>
  )
}

export type MarketingKanbanCardType = { KanbanCard: typeof MarketingKanbanCard }
