import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { getRoles, selectRoles } from '../../reducers/RoleReducer'
import { initialQuery } from '../../utils/helpers/crud/models'
import {
  getPermissions,
  selectPermissions,
} from '../../reducers/PermissionReducer'
import { useEffect, useState } from 'react'
import { TableParams } from '../../models/TableParams'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Card, Col, Row, Space, Spin, Tag } from 'antd'
import { Actions } from '../../modules/sbrm/components/Actions'
import { isRoleEditable } from '../../models/Role'
import type { CollapseProps } from 'antd'
import { Collapse } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

export const Roles = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const roles = useAppSelector(selectRoles())
  const permissions = useAppSelector(selectPermissions())

  const { isLoading: permissionsAreLoading } = useAppSelector(
    (state) => state.permission
  )

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const localizeRoleName = (name: string): string => {
    switch (name) {
      case 'visitor':
        return intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.Visitor,
        })
      case 'marketing':
        return intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.Marketing,
        })

      case 'production-manager':
        return intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.ProductionManager,
        })

      case 'financial-manager':
        return intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.financialManager,
        })

      case 'general-manager':
        return intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.GeneralManager,
        })

      case 'admin':
        return intl.formatMessage({
          id: LocalizationKeys.Components.Organization.Roles.Admin,
        })
      default:
        return name
    }
  }

  const getRolesAndPermissions = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 1000 },
    }
    // Fetch all roles & permissions
    dispatch(getRoles(baseQuery))
    dispatch(getPermissions(baseQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)

    // Trigger when SBRM is closed
    getRolesAndPermissions()
  }, [dispatch, SBRMIsOpen])

  useEffect(() => {
    getRolesAndPermissions()
  }, [])

  const items: CollapseProps['items'] = roles
    .sort((a, b) => {
      return b.id - a.id
    })
    .map((role) => ({
      key: role.id,
      label: (
        <Space className="w-full" style={{ justifyContent: 'space-between' }}>
          {localizeRoleName(role.name)}
          {isRoleEditable(role) && (
            <Actions
              actions={['update', 'delete']}
              entity={SBRMType.role}
              entityId={role.id}
            />
          )}
        </Space>
      ),
      children: (
        <Row gutter={[8, 8]}>
          {permissions.map((permission) => (
            <Col span={6} key={permission.id}>
              <Tag
                color={permission.roles.includes(role.id) ? 'green' : 'red'}
                icon={
                  permission.roles.includes(role.id) ? (
                    <CheckCircleOutlined />
                  ) : (
                    <CloseCircleOutlined />
                  )
                }
              >
                {permission.name}
              </Tag>
            </Col>
          ))}
        </Row>
      ),
    }))

  if (permissionsAreLoading) {
    return <Card loading={permissionsAreLoading} bordered={false}></Card>
  }

  return (
    <Collapse
      className="rolesAcordeon"
      items={items}
      bordered={false}
      defaultActiveKey={[]}
      style={{ alignItems: 'center' }}
    />
  )
}

export type RolesType = { Roles: typeof Roles }
