import { Space } from 'antd'
import { Comment } from './Comment'
import { useAppSelector } from '../../reducers/hooks'
import { selectComments } from '../../reducers/CommentReducer'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { selectExternalComments } from '../../reducers/ExternalCommentReducer'

interface Props {
  entity: SBRMType
}

export const CommentWrapper = ({ entity }: Props) => {
  const comments = useAppSelector(
    entity === SBRMType.externalAdvancingLink
      ? selectExternalComments()
      : selectComments()
  )

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Comment.List entity={entity} ids={comments.map((c) => c.id)} />
    </Space>
  )
}

export type CommentWrapperType = { Wrapper: typeof CommentWrapper }
