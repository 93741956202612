import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'antd'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { Kanban } from '../../components/kanban/Kanban'
import HasAccess from '../../components/HasAccess'
import { AlelaPermission } from '../../utils/permissions'
import { convertEventMarketingToKanbanData } from '../../components/kanban/utils'
import {
  getEventMarketingWithId,
  selectAllMarketingTaskStatus,
} from '../../reducers/MarketingTaskStatusReducer'
import { MarketingTask } from '../../components/marketing-task/MarketingTask'
import {
  selectAllMarketingTasks,
  updateMarketingTask,
} from '../../reducers/MarketingTaskReducer'
import { MarketingTask as MarketingTaskModel } from '../../models/MarketingTask'
import { Actions } from '../../modules/sbrm/components/Actions'
import { PlusOutlined } from '@ant-design/icons'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'

const MarketingPage = () => {
  const dispatch = useAppDispatch()
  const event = useAppSelector(selectSelectedEvent())
  const tasks = useAppSelector(selectAllMarketingTasks())
  const statuses = useAppSelector(selectAllMarketingTaskStatus())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [trigger, setTrigger] = useState(0)

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    // Trigger on init and when SBRM is closed
    setIsFirstRender(false)

    dispatch(getEventMarketingWithId(event?.id!)).then(() => {
      setTrigger(trigger + 1)
    })
  }, [SBRMIsOpen])

  if (statuses.length === 0) {
    return <></>
  }

  return (
    <HasAccess permissions={[AlelaPermission.listMarketingTask]}>
      <Row>
        <Col md={24} sm={24} xs={24}>
          <Kanban
            initialValue={convertEventMarketingToKanbanData<number>(statuses)}
            renderItem={(item) => <MarketingTask.KanbanCard id={item} />}
            columnToolbar={(columnId) => (
              <Actions
                bypassHasAccessCheck={true}
                actions={['create']}
                entity={SBRMType.marketingTask}
                trigger={<Button type="dashed" icon={<PlusOutlined />} />}
                metadata={[
                  { key: MetaDataKey.eventId, value: event?.id },
                  { key: MetaDataKey.marketingTaskStatusId, value: columnId },
                ]}
              />
            )}
            itemMoved={(draggableId, newColumnId, newIndex) => {
              dispatch(
                updateMarketingTask({
                  ...tasks.find((e) => e.id === Number(draggableId)),
                  status: newColumnId,
                  order: newIndex + 1, // Index starts at 0, order starts at 1
                } as MarketingTaskModel)
              )
            }}
          >
            <Kanban.Board
              data={convertEventMarketingToKanbanData<number>(statuses)}
              triggerData={trigger}
            />
          </Kanban>
        </Col>
      </Row>
    </HasAccess>
  )
}

export default MarketingPage
