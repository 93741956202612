import { useEffect } from 'react'
import { Alert, Card, Col, Row } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import { SBActivityLog } from '../../components/SBActivityLog'
import { selectSelectedEvent } from '../../reducers/EventReducer'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaDataKey } from '../../models/MetaData'

const SettingsPage = () => {
  const event = useAppSelector(selectSelectedEvent())
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (SBRMIsOpen || !event) {
      return
    }
    // Trigger on init and when SBRM is closed
    // dispatch(getEventWithId(event?.id))
  }, [SBRMIsOpen])

  return (
    <Row className="container-row">
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Alert
              message="Edit event infos"
              showIcon
              description="Add action description here"
              type="warning"
              action={
                <Actions
                  actions={['delete']}
                  entity={SBRMType.event}
                  entityId={event?.id}
                  metadata={[
                    {
                      key: MetaDataKey.deleteWithConfirmation,
                      value: true,
                    },
                  ]}
                />
              }
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Card bordered={false} title={'Activity log'}>
              <SBActivityLog activities={event?.activities ?? []} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SettingsPage
