// @flow
import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Item from './Item'
import type {
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Typography } from 'antd'
import { Card } from '../../models/Kanban'

const { Text } = Typography

export const getBackgroundColor = (
  isDraggingOver: boolean,
  isDraggingFrom: boolean
): string => {
  if (isDraggingOver) {
    return 'red'
  }
  if (isDraggingFrom) {
    return 'blue'
  }
  return 'inherit'
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;
  border: 1px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
`

const grid = 8
const scrollContainerHeight: number = 20

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: ${grid}px;
`

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`

/* stylelint-disable block-no-empty */
const Container = styled.div``
/* stylelint-enable */

type ListProps<T> = {
  listId?: string
  listType?: string
  cards: Card<T>[]
  title?: string
  internalScroll?: boolean
  scrollContainerStyle?: Object
  isDropDisabled?: boolean
  isCombineEnabled?: boolean
  style?: Object
  // may not be provided - and might be null
  ignoreContainerClipping?: boolean

  useClone?: boolean
}

type InnerInnerQuoteListProps<T> = {
  cards: Card<T>[]
}

const InnerInnerQuoteList = <T,>({ cards }: InnerInnerQuoteListProps<T>) => {
  return (
    <>
      {cards.map((card: Card<T>, index: number) => (
        <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
          {(
            dragProvided: DraggableProvided,
            dragSnapshot: DraggableStateSnapshot
          ) => (
            <Item
              key={card.id}
              card={card}
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
            />
          )}
        </Draggable>
      ))}
    </>
  )
}

const InnerQuoteList = React.memo(InnerInnerQuoteList)

InnerQuoteList.displayName = 'InnerQuoteList'

type InnerListProps<T> = {
  dropProvided: DroppableProvided
  cards: Card<T>[]
  title?: string
}

function InnerList<T>(props: InnerListProps<T>) {
  const { cards, dropProvided } = props
  const title = props.title ? <Text>{props.title}</Text> : null

  return (
    <Container>
      {title}
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteList cards={cards} />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  )
}

export default function List<T>(props: ListProps<T>) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    cards,
    title,
    useClone,
  } = props

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <Item
                card={cards[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
              />
            )
          : undefined
      }
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot
      ) => (
        <Wrapper
          //style={style}
          style={{
            opacity: dropSnapshot.isDraggingOver ? 0.5 : 1,
          }}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                cards={cards}
                title={title}
                dropProvided={dropProvided}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              cards={cards}
              title={title}
              dropProvided={dropProvided}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  )
}
