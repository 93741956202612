// @flow
import type { LaneMap } from './types'
import { Card, Lane } from '../../models/Kanban'
import type { DraggableLocation } from 'react-beautiful-dnd'
import { MarketingTaskStatus } from '../../models/MarketingTask'

// a little function to help us with reordering the result
const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default reorder

type ReorderLaneMapArgs<T> = {
  laneMap: LaneMap<T>
  source: DraggableLocation
  destination: DraggableLocation
}

export type ReorderLaneMapResult<T> = {
  laneMap: LaneMap<T>
}

/**
 * We use Object.assign() & [...] to create new object/array
 * This allow to make sure components will re-render
 */
export const reorderLaneMap = <T>({
  laneMap,
  source,
  destination,
}: ReorderLaneMapArgs<T>): ReorderLaneMapResult<T> => {
  const current: Lane<T> = Object.assign(laneMap[source.droppableId], {})
  const next: Lane<T> = Object.assign(laneMap[destination.droppableId], {})
  const target: Card<T> = laneMap[source.droppableId].cards[source.index]

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: Card<T>[] = reorder(
      current.cards,
      source.index,
      destination.index
    )
    const result: LaneMap<T> = {
      ...laneMap,
      [source.droppableId]: {
        ...laneMap[source.droppableId],
        cards: reordered,
      },
    }
    return {
      laneMap: result,
    }
  }

  // moving to different list

  // Create copies
  const currentCards = current.cards
  const nextCards = next.cards

  // Mutate
  currentCards.splice(source.index, 1) // remove from original
  nextCards.splice(destination.index, 0, target) // insert into next

  // Finally replace
  current.cards = [...currentCards]
  next.cards = [...nextCards]

  const result: LaneMap<T> = {
    ...laneMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  }

  return {
    laneMap: result,
  }
}

type List<T> = {
  id: string
  values: T[]
}

type MoveBetweenArgs<T> = {
  list1: List<T>
  list2: List<T>
  source: DraggableLocation
  destination: DraggableLocation
}

type MoveBetweenResult<T> = {
  list1: List<T>
  list2: List<T>
}

export function moveBetween<T>({
  list1,
  list2,
  source,
  destination,
}: MoveBetweenArgs<T>): MoveBetweenResult<T> {
  const newFirst = Array.from(list1.values)
  const newSecond = Array.from(list2.values)

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond
  const moveTo = moveFrom === newFirst ? newSecond : newFirst

  const [moved] = moveFrom.splice(source.index, 1)
  moveTo.splice(destination.index, 0, moved)

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  }
}

export const composeColumnId = (id: number): string => `c-${id.toString()}`
export const extractIdFromColumn = (columnId: string): number =>
  Number(columnId.slice(2))

export const convertEventMarketingToKanbanData = <T>(
  data: MarketingTaskStatus[]
): Lane<T>[] =>
  Object.assign(data, {}).map((status) => ({
    id: status.id,
    title: status.name,
    cards: (status.tasks ?? []).map((task: number) => ({
      id: task as unknown as number,
      rawItem: task as unknown as T,
    })),
  }))
