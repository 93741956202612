import { Col, Row, Space, Spin, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import { Room } from '../../../models/Room'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { SBRMType } from '../../../modules/sbrm/SBRMModel'
import { selectArtistsByIds } from '../../../reducers/ArtistReducer'
import { selectBookingsByIds } from '../../../reducers/BookingReducer'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import {
  getRooms,
  selectRooms,
  setRoomQuery,
} from '../../../reducers/RoomReducer'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { getIdsFromArray } from '../../../utils/helpers/ReducerHelper'
import { MetaDataKey } from '../../../models/MetaData'
import HasAccess from '../../../components/HasAccess'
import { Room as RoomC } from '../../../components/room/Room'
import { AlelaPermission } from '../../../utils/permissions'
import { extractSortDataFromSorterResult } from '../../../utils/table/sorter'
import { Hotel } from '../../../components/hotel/Hotel'
import { Booking } from '../../../components/booking/Booking'
import SBTable from '../../../components/SBTable'
import { Contact } from '../../../components/contact/Contact'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { Help } from '../../../components/help/Help'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'

const HotelsPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const bookingsIdsFromRooms = (rooms: Room[]) =>
    getIdsFromArray(rooms, (room) => room.booking)

  const event = useAppSelector(selectSelectedEvent())
  const rooms = useAppSelector(selectRooms())
  const bookings = useAppSelector(
    selectBookingsByIds(bookingsIdsFromRooms(rooms))
  )
  const artists = useAppSelector(
    selectArtistsByIds(bookings.map((b) => b.artist ?? 0))
  )
  const { query, isLoading } = useAppSelector((state) => state.room)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const columns: ColumnsType<Room> = [
    {
      key: 'bookings',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Hotels.Booking,
      }),
      width: 200,
      render: (record: Room) => <Booking.Avatar id={record.booking!} />,
      filters: bookings.map((booking) => ({
        text: artists.find((a) => a.id === booking.artist)
          ? artists.find((a) => a.id === booking.artist)!.name
          : '',
        value: booking.id,
      })),
    },
    {
      key: 'hotel',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Hotels.Hotel,
      }),
      render: (record: Room) => (
        <Space size={5} direction="vertical">
          <Hotel.Avatar id={record.hotel!} linkToDetails={false} />
          {(record.reference != null || record.category != null) && (
            <Space size={0}>
              {record.reference && <Tag>{record.reference}</Tag>}
              {record.category && <Tag>{record.category}</Tag>}
            </Space>
          )}
        </Space>
      ),
    },
    {
      key: 'price',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Hotels.Price,
      }),
      render: (record: Room) => <RoomC.Price id={record.id} />,
    },
    {
      key: 'dates',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Hotels.Dates,
      }),
      render: (record: Room) => <RoomC.Nights id={record.id} />,
    },
    {
      key: 'contacts',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Hotels.Travelers,
      }),
      align: 'center',
      render: (record: Room) => <Contact.AvatarGroup ids={record.contacts} />,
    },
    {
      key: 'notes',
      title: intl.formatMessage({
        id: LocalizationKeys.Page.EventDetails.Hotels.Notes,
      }),
      dataIndex: 'notes',
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: Room) => (
        <Actions
          actions={['update', 'duplicate', 'delete']}
          entity={SBRMType.room}
          entityId={record.id}
          metadata={[{ key: MetaDataKey.eventId, value: event?.id }]}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Room> | SorterResult<Room>[]
  ) => {
    const newQuery = {
      pagination,
      filters: { ...filters, ...{ events: [event?.id!] } },
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setRoomQuery(newQuery))
    dispatch(getRooms(newQuery))
  }

  useEffect(() => {
    if (!isFirstRender && SBRMIsOpen) {
      return
    }
    setIsFirstRender(false)
    // Trigger getVenues when SBRM is closed
    const newQuery = {
      ...initialQuery,
      filters: { events: [event?.id!] },
    }
    dispatch(setRoomQuery(newQuery))
    dispatch(getRooms(newQuery))
  }, [SBRMIsOpen])

  if (event === undefined) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listRoom]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.Hotels.Title,
            })}
            level={5}
            badge={rooms.length}
            toolbar={
              <Space>
                <Actions
                  actions={['create']}
                  entity={SBRMType.room}
                  metadata={[{ key: MetaDataKey.eventId, value: event.id }]}
                />
                <Booking.Export eventId={event.id} entity={SBRMType.room} />
                <Help.Button article={HelpArticle.AddAndManageHotelRooms} />
              </Space>
            }
          />
          <RoomC.ExternalRequests
            eventId={event.id}
            shouldRefreshList={() => {
              dispatch(getRooms(query))
            }}
          />
          <SBTable
            entity={SBRMType.room}
            emptyActionsMetadata={[
              { key: MetaDataKey.eventId, value: event.id },
            ]}
            scroll={{ x: 1000 }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={rooms}
            loading={isLoading && !rooms.length}
            pagination={query.pagination}
            onChange={handleTableChange}
          />
        </HasAccess>
      </Col>
    </Row>
  )
}

export default HotelsPage
