import {
  getBookings,
  resetBookingQueryAndIds,
  selectBookings,
  setBookingQuery,
} from '../../../reducers/BookingReducer'
import {
  SBRMContactAssociationType,
  SBRMType,
} from '../../../modules/sbrm/SBRMModel'
import { useIntl } from 'react-intl'
import { Col, Row, Space, Spin, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { PageTitle } from '@supplement-bacon/alela-uikit'
import HasAccess from '../../../components/HasAccess'
import { MetaDataKey } from '../../../models/MetaData'
import { TableParams } from '../../../models/TableParams'
import { AlelaPermission } from '../../../utils/permissions'
import { Booking } from '../../../components/booking/Booking'
import { ArtistAvatar } from '../../../components/artist/Avatar'
import { initialQuery } from '../../../utils/helpers/crud/models'
import { selectSelectedEvent } from '../../../reducers/EventReducer'
import { Actions } from '../../../modules/sbrm/components/Actions'
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { HelpArticle } from '../../../utils/hooks/useAlelaHelp'
import { Help } from '../../../components/help/Help'
import { Event } from '../../../components/event/Event'

const PartyCrewPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [activeTabBookingId, setActiveTabBookingId] = useState<
    string | undefined
  >(undefined)

  const event = useAppSelector(selectSelectedEvent())
  const bookings = useAppSelector(selectBookings())
  const { isLoading: contactsAreLoading } = useAppSelector(
    (state) => state.contact
  )

  const bookingsMappedToTab = bookings.map((booking) => ({
    label: <ArtistAvatar id={booking.artist} linkToDetails={false} />,
    key: String(booking.id),
    children: (
      <Booking.ContactsTable
        //   ref={}
        bookingId={booking.id}
        emptyActionsMetadata={[
          {
            key: MetaDataKey.contactAssociationType,
            value: SBRMContactAssociationType.booking,
          },
          { key: MetaDataKey.eventId, value: event?.id },
          { key: MetaDataKey.bookingId, value: activeTabBookingId },
        ]}
        onImportFinished={() => {}}
      />
    ),
  }))

  const fetchData = () => {
    const baseQuery: TableParams = {
      ...initialQuery,
      pagination: { current: 1, pageSize: 100 },
      filters: { events: [event?.id!] },
      with: ['artist', 'artist.contacts'], // Allow to display contact importer on Booking.ContactsTable
    }
    dispatch(setBookingQuery(baseQuery))
    dispatch(getBookings(baseQuery))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!bookings.length || activeTabBookingId !== undefined) {
      return
    }
    // Set intial selected booking ID only if activeTabBookingId is not already defined
    setActiveTabBookingId(bookings[0].id.toString())
  }, [bookings.length])

  if (event === undefined) return <Spin />

  return (
    <Row className="container-row">
      <Col span={24}>
        <HasAccess permissions={[AlelaPermission.listContactAssociation]}>
          <PageTitle
            title={intl.formatMessage({
              id: LocalizationKeys.Page.EventDetails.PartyCrew,
            })}
            level={5}
            toolbar={
              <Help.Button article={HelpArticle.AddAndManagePartyCrew} />
            }
          />
          <Event.PartyCrewExternalRequests
            bookings={bookings}
            shouldRefreshList={(association) => {
              setActiveTabBookingId(association.contactable!.id.toString())
              dispatch(resetBookingQueryAndIds(initialQuery))
              fetchData()
            }}
          />
          <Tabs
            items={bookingsMappedToTab}
            destroyInactiveTabPane={true}
            defaultActiveKey={activeTabBookingId}
            activeKey={activeTabBookingId}
            onChange={(activeKey) => setActiveTabBookingId(activeKey)}
            tabBarExtraContent={
              <Space>
                {contactsAreLoading && <Spin />}
                <Actions
                  actions={['create']}
                  entity={SBRMType.contactAssociation}
                  metadata={[
                    {
                      key: MetaDataKey.contactAssociationType,
                      value: SBRMContactAssociationType.booking,
                    },
                    { key: MetaDataKey.eventId, value: event.id },
                    {
                      key: MetaDataKey.bookingId,
                      value: Number(activeTabBookingId),
                    },
                  ]}
                />
              </Space>
            }
          />
        </HasAccess>
      </Col>
    </Row>
  )
}

export default PartyCrewPage
