import { FC } from 'react'
import { EventCell, EventCellType } from './Cell'
import {
  AutoImportVenueContacts,
  AutoImportVenueContactsType,
} from './AutoImportVenueContacts'
import { EventCalendarCell, EventCalendarCellType } from './CalendarCell'
import { EventOption, EventOptionType } from './Option'
import { EventStatusTag, EventStatusTagType } from './StatusTag'
import { EventCover, EventCoverType } from './Cover'
import { EventForm, EventFormType } from './form/Form'
import { Calendar, CalendarType } from './Calendar'
import {
  PartyCrewExternalRequests,
  PartyCrewExternalRequestsType,
} from './PartyCrewExternalRequests'

export const Event: FC &
  EventCellType &
  EventCalendarCellType &
  EventOptionType &
  EventStatusTagType &
  EventCoverType &
  AutoImportVenueContactsType &
  EventFormType &
  CalendarType &
  PartyCrewExternalRequestsType = (): JSX.Element => <></>

Event.Cell = EventCell
Event.CalendarCell = EventCalendarCell
Event.Option = EventOption
Event.StatusTag = EventStatusTag
Event.Cover = EventCover
Event.AutoImportVenueContacts = AutoImportVenueContacts
Event.Form = EventForm
Event.Calendar = Calendar
Event.PartyCrewExternalRequests = PartyCrewExternalRequests
